<template>
  <div style="padding-bottom: 80px">
    <b-card title="TUSBuddy Üyeleri">
      <b-tabs>
        <b-tab title="Tüm Kullanıcılar">
          <b-row>
            <b-col cols="12" md="3" class="mt-1">
              <b-form-input
                id="nameFilter"
                placeholder="Ad filtrele"
                v-model="nameFilter"
              />
            </b-col>

            <b-col cols="12" md="3" class="mt-1">
              <b-form-input
                id="surnameFilter"
                placeholder="Soyad filtrele"
                v-model="surnameFilter"
              />
            </b-col>

            <b-col cols="12" md="3" class="mt-1">
              <b-form-input
                id="usernameFilter"
                placeholder="Kullanıcı adı filtrele"
                v-model="usernameFilter"
              />
            </b-col>

            <b-col cols="12" md="3" class="mt-1">
              <b-form-input
                id="emailFilter"
                placeholder="E-posta filtrele"
                v-model="emailFilter"
              />
            </b-col>
          </b-row>

          <div style="min-height: 300px">
            <b-table
              responsive
              hover
              class="mt-1"
              :items="userList"
              :fields="userListTableFields"
              selectable
              select-mode="single"
              @row-selected="onUserSelected"
            >
              <template #cell(name)="data">
                <p style="margin-bottom: 0px">
                  {{ data.item.name }}
                </p>
              </template>
              <template #cell(surname)="data">
                <p style="margin-bottom: 0px">
                  {{ data.item.surname }}
                </p>
              </template>
              <template #cell(user_name)="data">
                <p style="margin-bottom: 0px">
                  {{ data.item.user_name }}
                </p>
              </template>
              <template #cell(email)="data">
                <p style="margin-bottom: 0px">
                  {{ data.item.email }}
                </p>
              </template>
              <template #cell(tus_analiz_user)="data">
                <div style="min-width: 160px">
                  <b-button
                    v-if="data.item.tus_analiz_user"
                    variant="primary"
                    size="sm"
                    @click="removeTusAnalizUser(data.item)"
                  >
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    <span class="align-middle">TUS Analiz Üyesi</span>
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-primary"
                    size="sm"
                    @click="addTusAnalizUser(data.item)"
                  >
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    <span class="align-middle">Standart Üye</span>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab title="TUS Analiz">
          <b-form-input
            placeholder="Ad-soyad, kullanıcı adı veya e-posta filtrele"
            class="mt-1 mb-2"
            v-model="tusAnalizUserFilter"
          />

          <b-table
            responsive
            hover
            :per-page="tusAnalizUsersPerPage"
            :current-page="tusAnalizUsersCurrentPage"
            select-mode="single"
            :items="filteredTusAnalizUsers"
            :fields="tusAnalizUsersTableFields"
            style="min-height: 260px"
          >
            <template #cell(name)="data">
              <p style="margin-bottom: 0px">
                {{ data.item.name }}
              </p>
            </template>
            <template #cell(surname)="data">
              <p style="margin-bottom: 0px">
                {{ data.item.surname }}
              </p>
            </template>
            <template #cell(user_name)="data">
              <p style="margin-bottom: 0px">
                {{ data.item.user_name }}
              </p>
            </template>
            <template #cell(email)="data">
              <p style="margin-bottom: 0px">
                {{ data.item.email }}
              </p>
            </template>
            <template #cell(action)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="
                  selectedTusAnalizUser = data.item;
                  $refs['modal-remove-tus-analiz-user'].show();
                "
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span class="align-middle">Kaldır</span>
              </b-button>
            </template>
          </b-table>

          <div class="mt-1">
            <b-pagination
              v-model="tusAnalizUsersCurrentPage"
              :total-rows="tusAnalizUsers.length"
              :per-page="tusAnalizUsersPerPage"
              align="right"
              class="mb-2"
            />
          </div>

          <b-modal
            id="modal-remove-tus-analiz-user"
            ref="modal-remove-tus-analiz-user"
            hide-footer
            centered
            title="Üyeyi Kaldır"
          >
            <div v-if="selectedTusAnalizUser">
              <p class="m-1">
                {{ selectedTusAnalizUser.user_name }}, TUS Analiz listesinden
                silinecek.
              </p>

              <hr style="margin: 20px 10px" />
              <div>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="primary"
                  @click="$refs['modal-remove-tus-analiz-user'].hide()"
                >
                  Kapat
                </b-button>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="danger"
                  @click="
                    removeTusAnalizUser(selectedTusAnalizUser);
                    $refs['modal-remove-tus-analiz-user'].hide();
                  "
                >
                  Kaldır
                </b-button>
              </div>
            </div>
          </b-modal>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-card v-if="selectedUser">
      <div style="display: flex; margin-top: 5px">
        <div style="flex-grow: 1">
          <h4>Kullanıcı Bilgileri</h4>
        </div>
        <div>
          <b-button
            @click="selectedUser = null"
            variant="outline-danger"
            pill
            size="sm"
          >
            Kapat
          </b-button>
        </div>
      </div>

      <b-row class="my-1">
        <b-col cols="12" md="6" lg="4" class="mt-1">
          <b-form-group label="Ad" label-for="name">
            <b-form-input id="name" readonly v-model="selectedUser.name" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4" class="mt-1">
          <b-form-group label="Soyad" label-for="surname">
            <b-form-input
              id="surname"
              readonly
              v-model="selectedUser.surname"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4" class="mt-1">
          <b-form-group label="Kullanıcı Adı" label-for="username">
            <b-form-input
              id="username"
              readonly
              v-model="selectedUser.user_name"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="E-Posta" label-for="email">
            <b-form-input id="email" readonly v-model="selectedUser.email" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="Hedef Sınav" label-for="targetExam">
            <b-form-input id="targetExam" readonly v-model="targetExam" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col cols="12" md="6">
          <b-form-group label="Kayıt Tarihi" label-for="register_time">
            <b-form-input
              id="register_time"
              readonly
              v-model="selectedUser.register_time"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Performans Puanı" label-for="performance_score">
            <b-form-input
              id="performance_score"
              readonly
              v-model="selectedUser.performance_score"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="selectedUser && trialExamResultsOfSelectedUser"
      :title="
        selectedUser.user_name +
        ' - Deneme Sınavları (' +
        trialExamResultsOfSelectedUser.length +
        ' Sınav)'
      "
    >
      <b-table
        responsive
        hover
        class="mt-1"
        :per-page="trialExamResultsPerPage"
        :current-page="trialExamResultsCurrentPage"
        :items="trialExamResultsOfSelectedUser"
        :fields="trialExamResultsTableFields"
      >
        <template #cell(exam_name)="data">
          {{ data.item.exam_name }}
        </template>

        <template #cell(basic_net)="data">
          {{ data.item.t_c - 0.25 * data.item.t_w }}
        </template>

        <template #cell(clinical_net)="data">
          {{ data.item.k_c - 0.25 * data.item.k_w }}
        </template>

        <template #cell(basic_score)="data">
          {{ data.item.t_point }}
        </template>

        <template #cell(clinical_score)="data">
          {{ data.item.k_point }}
        </template>

        <template #cell(action)="data">
          <b-button
            variant="flat-danger"
            size="sm"
            @click="showRemoveTrialExamParticipationPopup(data.item)"
            style="min-width: 90px"
          >
            <feather-icon icon="MinusCircleIcon" style="margin-right: 10px" />
            <span class="align-middle" style="font-size: 0.95rem">{{
              "Kaldır"
            }}</span>
          </b-button>
        </template>
      </b-table>

      <div class="mt-1; display: flex;">
        <div class="flex-grow: 1"></div>
        <div>
          <b-pagination
            v-if="
              trialExamResultsOfSelectedUser.length > trialExamResultsPerPage
            "
            v-model="trialExamResultsCurrentPage"
            :total-rows="trialExamResultsOfSelectedUser.length"
            :per-page="trialExamResultsPerPage"
            :align="'right'"
          />
        </div>
      </div>

      <b-modal
        id="modal-remove-trial-exam-participation"
        ref="modal-remove-trial-exam-participation"
        hide-footer
        centered
        title="Sınavı Kaldır"
      >
        <div v-if="selectedTrialExamToRemove">
          <p class="m-1">
            {{ selectedTrialExamToRemove.exam_name }} katılımı silinecek. Bu işlem
            geri alınamaz
          </p>

          <hr style="margin: 20px 10px" />
          <div>
            <b-button
              class="mr-1"
              style="float: right"
              variant="primary"
              @click="$refs['modal-remove-trial-exam-participation'].hide()"
            >
              Kapat
            </b-button>
            <b-button
              class="mr-1"
              style="float: right"
              variant="danger"
              @click="removeSelectedTrialExamParticipation()"
            >
              Kaldır
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-card>

    <b-card
      v-if="selectedUser && lessonExamResultsOfSelectedUser"
      :title="
        selectedUser.user_name +
        ' - Ders Denemeleri (' +
        lessonExamResultsOfSelectedUser.length +
        ' Sınav)'
      "
    >
      <b-table
        responsive
        hover
        class="mt-1"
        :per-page="lessonExamResultsPerPage"
        :current-page="lessonExamResultsCurrentPage"
        :items="lessonExamResultsOfSelectedUser"
        :fields="lessonExamResultsTableFields"
      >
        <template #cell(title)="data">
          {{ data.item.title + " - " + data.item.exam_order }}
        </template>

        <template #cell(action)="data">
          <b-button
            variant="flat-danger"
            size="sm"
            @click="showRemoveLessonExamParticipationPopup(data.item)"
            style="min-width: 90px"
          >
            <feather-icon icon="MinusCircleIcon" style="margin-right: 10px" />
            <span class="align-middle" style="font-size: 0.95rem">{{
              "Kaldır"
            }}</span>
          </b-button>
        </template>
      </b-table>

      <div class="mt-1; display: flex;">
        <div class="flex-grow: 1"></div>
        <div>
          <b-pagination
            v-if="
              lessonExamResultsOfSelectedUser.length > lessonExamResultsPerPage
            "
            v-model="lessonExamResultsCurrentPage"
            :total-rows="lessonExamResultsOfSelectedUser.length"
            :per-page="lessonExamResultsPerPage"
            :align="'right'"
          />
        </div>
      </div>

      <b-modal
        id="modal-remove-lesson-exam-participation"
        ref="modal-remove-lesson-exam-participation"
        hide-footer
        centered
        title="Sınavı Kaldır"
      >
        <div v-if="selectedLessonExamToRemove">
          <p class="m-1">
            {{ selectedLessonExamToRemove.title + " - " + selectedLessonExamToRemove.exam_order }} katılımı silinecek. Bu işlem
            geri alınamaz
          </p>

          <hr style="margin: 20px 10px" />
          <div>
            <b-button
              class="mr-1"
              style="float: right"
              variant="primary"
              @click="$refs['modal-remove-lesson-exam-participation'].hide()"
            >
              Kapat
            </b-button>
            <b-button
              class="mr-1"
              style="float: right"
              variant="danger"
              @click="removeSelectedLessonExamParticipation()"
            >
              Kaldır
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserResult from "@/views/admin/AdminUserExamResult.vue";

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    UserResult,
  },
  data() {
    return {
      userList: [],
      selectedUser: null,
      trialExamResultsOfSelectedUser: [],
      lessonExamResultsOfSelectedUser: [],
      userListTableFields: [
        { key: "name", label: "Ad" },
        { key: "surname", label: "Soyad" },
        { key: "user_name", label: "Kullanıcı Adı" },
        { key: "email", label: "E-Posta" },
        { key: "tus_analiz_user", label: "" },
      ],
      nameFilter: "",
      surnameFilter: "",
      usernameFilter: "",
      emailFilter: "",
      trialExamResultsTableFields: [
        { key: "exam_name", label: "Sınav" },
        { key: "basic_net", label: "Temel Neti" },
        { key: "clinical_net", label: "Klinik Neti" },
        { key: "basic_score", label: "Temel Puanı" },
        { key: "clinical_score", label: "Klinik Puanı" },
        { key: "action", label: "" },
      ],
      trialExamResultsPerPage: 5,
      trialExamResultsCurrentPage: 1,
      selectedTrialExamToRemove: null,
      //
      lessonExamResultsTableFields: [
        { key: "title", label: "Sınav" },
        { key: "correct_count", label: "Doğru" },
        { key: "wrong_count", label: "Yanlış" },
        { key: "net", label: "Net" },
        { key: "score", label: "Puan" },
        { key: "action", label: "" },
      ],
      lessonExamResultsPerPage: 5,
      lessonExamResultsCurrentPage: 1,
      selectedLessonExamToRemove: null,
      //
      tusAnalizUserFilter: "",
      tusAnalizUsersPerPage: 5,
      tusAnalizUsersCurrentPage: 1,
      tusAnalizUsers: [],
      selectedTusAnalizUser: null,
      tusAnalizUsersTableFields: [
        { key: "name", label: "Ad" },
        { key: "surname", label: "Soyad" },
        { key: "user_name", label: "Kullanıcı Adı" },
        { key: "email", label: "E-Posta" },
        { key: "action", label: "" },
      ],
    };
  },
  watch: {
    nameFilter: function (newValue) {
      this.loadUserList();
    },
    surnameFilter: function (newValue) {
      this.loadUserList();
    },
    usernameFilter: function (newValue) {
      this.loadUserList();
    },
    emailFilter: function (newValue) {
      this.loadUserList();
    },
  },

  computed: {
    config() {
      return {
        locale: Turkish,
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      };
    },
    targetExam() {
      var target = this.selectedUser.target_exam_id + "";
      if (target.length != 5) {
        return "???";
      }
      return target.substring(0, 4) + " - " + target.substring(4, 5) + ". TUS";
    },
    filteredTusAnalizUsers() {
      var filteredUsers = [];
      var filter = this.tusAnalizUserFilter.trim().toLocaleUpperCase("tr-TR");
      this.tusAnalizUsers.forEach(function (user) {
        if (
          (user.name + " " + user.surname + user.user_name + user.email)
            .toLocaleUpperCase("tr-TR")
            .includes(filter)
        ) {
          filteredUsers.push(user);
        }
      });
      return filteredUsers;
    },
  },

  methods: {
    showRemoveTrialExamParticipationPopup(trialExam) {
      if (!trialExam) {
        return;
      }
      this.selectedTrialExamToRemove = trialExam;
      this.$refs["modal-remove-trial-exam-participation"].show();
    },

    async removeSelectedTrialExamParticipation() {
      this.$refs["modal-remove-trial-exam-participation"].hide();
      if (!this.selectedTrialExamToRemove) {
        return;
      }
      var payload = {
        user_id: this.selectedTrialExamToRemove.user_id,
        trial_exam_id: this.selectedTrialExamToRemove.trial_exam_id,
      };
      await this.$store.dispatch(
        "adminUsers/removeTrialExamParticipation",
        payload
      );
      var exams = await this.$store.dispatch(
        "adminUsers/getTrialExamResults",
        this.selectedUser.a_id
      );
      if (exams) {
        this.trialExamResultsOfSelectedUser = exams;
      }
      this.$forceUpdate();
    },

    showRemoveLessonExamParticipationPopup(lessonExam) {
      if (!lessonExam) {
        return;
      }
      this.selectedLessonExamToRemove = lessonExam;
      this.$refs["modal-remove-lesson-exam-participation"].show();
    },

    async removeSelectedLessonExamParticipation() {
      this.$refs["modal-remove-lesson-exam-participation"].hide();
      if (!this.selectedLessonExamToRemove) {
        return;
      }
      var payload = {
        user_id: this.selectedLessonExamToRemove.user_id,
        lesson_exam_id: this.selectedLessonExamToRemove.lesson_exam_id,
      };
      await this.$store.dispatch(
        "adminUsers/removeLessonExamParticipation",
        payload
      );
      var exams = await this.$store.dispatch(
        "adminUsers/getLessonExamResults",
        this.selectedUser.a_id
      );
      if (exams) {
        this.lessonExamResultsOfSelectedUser = exams;
      }
      this.$forceUpdate();
    },

    isSameDateTime(dt1, dt2) {
      if ((!dt1 && dt2) || (dt1 && !dt2)) {
        return false;
      }
      if (!dt1 && !dt2) {
        return true;
      }
      return dt1.substring(0, 16) == dt2.substring(0, 16);
    },

    async addTusAnalizUser(user) {
      var updated = await this.$store.dispatch(
        "adminUsers/addTusAnalizUser",
        user.a_id
      );
      if (updated) {
        user.tus_analiz_user = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Üye türü değiştirildi.",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarısız",
            icon: "BellIcon",
            text: "Üye türü değiştirilemedi!",
            variant: "danger",
          },
        });
      }
      this.loadUserList();
      this.$forceUpdate();
    },

    async removeTusAnalizUser(user) {
      var updated = await this.$store.dispatch(
        "adminUsers/removeTusAnalizUser",
        user.a_id
      );
      if (updated) {
        user.tus_analiz_user = 0;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Üye türü değiştirildi.",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarısız",
            icon: "BellIcon",
            text: "Üye türü değiştirilemedi!",
            variant: "danger",
          },
        });
      }
      this.loadUserList();
      this.$forceUpdate();
    },

    onUserSelected(users) {
      if (!users || users.length == 0) {
        return;
      }
      var user = users[0];
      this.selectedUser = user;
      this.trialExamResultsOfSelectedUser = [];
      this.lessonExamResultsOfSelectedUser = [];
      if (user) {
        this.$store
          .dispatch("adminUsers/getTrialExamResults", this.selectedUser.a_id)
          .then((response) => {
            if (response) {
              this.trialExamResultsOfSelectedUser = response;
            }
          });

        this.$store
          .dispatch("adminUsers/getLessonExamResults", this.selectedUser.a_id)
          .then((response) => {
            if (response) {
              this.lessonExamResultsOfSelectedUser = response;
            }
          });
      }
    },

    async loadUserList() {
      this.tusAnalizUsers = await store.dispatch(
        "adminUsers/getTusAnalizUsers"
      );
      var payload = {
        nameFilter: this.nameFilter ? this.nameFilter : "",
        surnameFilter: this.surnameFilter ? this.surnameFilter : "",
        usernameFilter: this.usernameFilter ? this.usernameFilter : "",
        emailFilter: this.emailFilter ? this.emailFilter : "",
      };
      this.userList = await store.dispatch("adminUsers/getUsers", payload);
    },

    dateTimeToDayText(dateTime) {
      if (!dateTime || dateTime.trim().length != 19) {
        return "-";
      }
      dateTime = dateTime.trim();
      var date = dateTime.split(" ")[0];
      var year = date.split("-")[0];
      var month = date.split("-")[1];
      var day = date.split("-")[2];
      switch (month) {
        case "01":
          return day + " Ocak " + year;
        case "02":
          return day + " Şubat " + year;
        case "03":
          return day + " Mart " + year;
        case "04":
          return day + " Nisan " + year;
        case "05":
          return day + " Mayıs " + year;
        case "06":
          return day + " Haziran " + year;
        case "07":
          return day + " Temmuz " + year;
        case "08":
          return day + " Ağustos " + year;
        case "09":
          return day + " Eylül " + year;
        case "10":
          return day + " Ekim " + year;
        case "11":
          return day + " Kasım " + year;
        case "12":
          return day + " Aralık " + year;
      }
      return "-";
    },
    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },
  },
  async created() {
    let moduleId = "members";
    let moduleName = "Üyeler"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    await this.loadUserList();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
